var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "thank",
    },
    [
      _c(
        "div",
        {
          staticClass: "thank-wrap",
        },
        [
          _c(
            "form",
            {
              staticClass: "form",
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/anniversary_2023/thank/check.svg"),
                  alt: "check",
                },
              }),
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "btn-thank-group",
                },
                [
                  _c(
                    "button",
                    {
                      attrs: {
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloand(_vm.childFile1)
                        },
                      },
                    },
                    [_vm._v("下載 家長必讀雙語兒童養成辦法")]
                  ),
                  _c(
                    "button",
                    {
                      attrs: {
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.downloand(_vm.childFile2)
                        },
                      },
                    },
                    [_vm._v("下載 劍橋兒童英檢題庫")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "p",
      {
        staticClass: "text-center",
      },
      [
        _vm._v("謝謝您提供的訊息，我們已經收到您的資料！"),
        _c("br"),
        _vm._v("我們會盡快安排專員與您聯絡！"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }