// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/newYear_2024/thank/bg-s.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/newYear_2024/thank/bg-l.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".thank[data-v-f4313228] {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n@media (min-width: 768px) {\n.thank[data-v-f4313228] {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n}\n.thank-wrap[data-v-f4313228] {\n  padding: 0 16px;\n}\n@media (min-width: 768px) {\n.thank-wrap[data-v-f4313228] {\n      padding: 0;\n}\n}\n.form[data-v-f4313228] {\n  background: rgba(255, 255, 255, 0.4);\n  border: 1px solid #fff;\n  border-radius: 20px;\n  text-align: center;\n  width: 100%;\n  padding: 16px;\n}\n@media (min-width: 1024px) {\n.form[data-v-f4313228] {\n      padding: 40px;\n      width: 569px;\n}\n}\n.form img[data-v-f4313228] {\n    margin-bottom: 40px;\n}\n.form p[data-v-f4313228] {\n    font-size: 14px;\n    line-height: 20px;\n    padding: 40px 0;\n    background: rgba(255, 255, 255, 0.6);\n    border-radius: 15px;\n    margin-bottom: 40px;\n}\n.form button[data-v-f4313228] {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n    border: none;\n    outline: none;\n    background: #006BCF;\n    color: #fff;\n    border-radius: 5px;\n    padding: 15px 0;\n    width: 100%;\n    max-width: 322px;\n}\n.form button[data-v-f4313228]:first-child {\n      margin-bottom: 20px;\n}\n.form button[data-v-f4313228]:hover {\n      background: #369eff;\n}\n.btn-thank-group[data-v-f4313228] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n", ""]);
// Exports
module.exports = exports;
